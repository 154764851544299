import * as React from "react";
import { NavLink } from "react-router-dom";
import { 
	labels,
	useDevices,
} from '../Helpers/config';
// import Head from '../Components/Head';


function About(){

	return (
		<div className="page page--about">

			<div className="about">

				<NavLink to={`${process.env.PUBLIC_URL}/`} className="page__back">
					<strong>&lt;</strong> {labels.about}
				</NavLink>

				<p className="about__head">
					<strong>Prijaton is an audio streaming service</strong>, providing music for specific geographical landscapes.&nbsp;
					Open <u><NavLink to={`${process.env.PUBLIC_URL}/all`}>the map</NavLink></u> to explore the locations around you and if you like it, <u><NavLink to={`${process.env.PUBLIC_URL}/support`}>support us</NavLink></u>.
				</p>

			</div>

			<div className="about-list">

					<div className="about-list__item about-list__item--area">
						<img src={`img/markerLocation.png?cb=${/*Math.random()*/1}`} alt="" />
						<p>
							Geographical area with audio compositions.
							<br />
							<small>
								Some landscapes provide layered compositions scattered in space.
							</small>
						</p>
					</div>

					<div className="about-list__item about-list__item--location">
						<img src={`img/marker.png?cb=${/*Math.random()*/1}`} alt="" />
						<p>
							Your current geographical position.
							<br />
							<small>
								Please enable geolocation services to use the application.
							</small>
						</p>
					</div>

					{ useDevices === true && 
						<div className="about-list__item about-list__item--device">
							<img src={`img/markerDevice.png?cb=${/*Math.random()*/1}`} alt="" />
							<p>
								Locator is a device that transmits it's location.
								<br />
								<small>
									If available, scan the QR code to follow device's location.
								</small>
							</p>
						</div>
					}

				</div>
		</div>
	);

}

export default About;