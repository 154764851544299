import react from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Hello from '../Pages/Hello';
import About from '../Pages/About';
import Support from '../Pages/Support';
import Posts from '../Pages/Posts';
import Areas from '../Pages/Areas';
import NotFound from '../Pages/NotFound';



class Main extends react.Component{

	render(){
		
		return(

			<div className='main'>
				<BrowserRouter>
					<Routes>

						<Route 	exact 
								path={`${process.env.PUBLIC_URL}`}
								element={<Hello />} />

						<Route 	exact 
								path={`${process.env.PUBLIC_URL}/posts`}
								element={<Posts />} />

						<Route 	exact 
								path={`${process.env.PUBLIC_URL}/about`}
								element={<About />} />
						
						<Route 	exact 
								path={`${process.env.PUBLIC_URL}/support`}
								element={<Support />} />
						
						<Route 	exact
								path={`${process.env.PUBLIC_URL}/all`}
								element={<Areas checkParams={false} />} />
						
						<Route 	path={`${process.env.PUBLIC_URL}/@:params`}
								element={<Areas checkParams={true} />} />

						<Route 	path="*"
								element={<NotFound />} />


					</Routes>
				</BrowserRouter>
			</div>

		)
	}

}

export default Main;