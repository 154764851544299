import L from "leaflet";

export const baseUrl = (process.env.NODE_ENV === 'development')
	? 'http://localhost/geolocs/'
	: 'https://www.prijaton.com/';


export const noCoordsLatLng = [55.519325, 8.392709];
export const noCoordsZoom = 4;
export const zoom = 15;

export const basicFill = { fillColor: '#222', fillOpacity: .0, stroke: false }
export const activeFill = { fillColor: '#00f', fillOpacity: .0, stroke: false }
export const checkAreasInterval = 10000;


export const deviceSecondsToRemove = 60;
export const fadeOutSingleInterval = 1000;
export const fadeOutAllInterval = 1000;
export const audioVolumeInitial = .75;

// https://wiki.openstreetmap.org/wiki/Raster_tile_providers
// export const leafletUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.{ext}';
export const leafletUrl = 'https://maps.geoapify.com/v1/tile/toner/{z}/{x}/{y}.png?apiKey=61f164a128994177b9407bf0c03cdacf';
export const leafletUrlRetina = 'https://maps.geoapify.com/v1/tile/toner/{z}/{x}/{y}@2x.png?apiKey=61f164a128994177b9407bf0c03cdacf';

export const leafletAttribution = 'Powered by <a href="https://www.geoapify.com/">Geoapify</a>';

export const newsletterIframeUrl = "https://af618b71.sibforms.com/serve/MUIEAMmv4xCr7JHVWhV5xtlayq3BaRs0CMqlNPy_0T5bRk3sbE4X0UmRv9-_hPCV63mvONLRYs7u0nokrL-0rJFI3bpvyj7gDWLufO2b7gRyChu9LOrhG_2TkzfkImTuGkuptPfRee1tF3naQAfegRyDZUvCDMAaILKHJI7NQy0tnwU9R7zFKq8x9FYCQFlLFGf7PYFR07ekc7dc";

export const useDevices = true;
export const useClosest = false;

export const categories = [
	{
		key: 	'music',
		title: 	'Musical',
	},
	{
		key: 	'info',
		title: 	'Information',
	},
	{
		key: 	'promotion',
		title: 	'Promotions',
	},
];


export const labels = {
	about: 		'About',
	news: 		'News',
	support: 	'Support',
	explore: 	'Explore',

	no_pots:	'No posts so far.',

	areas:		{
		loadingAreas: 				'Loading landscapes...',

		trackLoading: 					'Loading',
		trackTitle: 					'track',

		locationMarkerTitle:		'Your Location',
		locationMarkerText:			'This is your current location.',	
		locatorMarkerTitle:			'Your Locator',
		locatorMarkerText:			'This is current location of your locator.',
		
		// enableLocation:				'Please enable location or add a locator...',
		enableLocation:				'Please enable location services and <u>reload the page</u>.',

		me:							'you',
	},

	locator: 	{
		title: 				'locator',
		add: 				'add Locator',
		waitingFor:			'connecting',

		doScan:				'Please scan your locator\'s QR code',
		doManual:			'or manually add locator\'s code',

		waitingForFull:		'Waiting for data from locator...',
		zoomTo:				'Go to Locator',
		remove:				'Remove Locator',

		closeModal:			'Close',
		
	}
}



export const icon = new L.icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	popupAnchor: [0, -32],
	iconUrl: `img/marker.png?cb=${/*Math.random()*/1}`,
});

export const iconDevice = new L.icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	popupAnchor: [0, -32],
	iconUrl: `img/markerDevice.png?cb=${/*Math.random()*/1}`,
});

export const iconLocation = new L.icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	popupAnchor: [0, -32],
	iconUrl: `img/markerLocation.png?cb=${/*Math.random()*/1}`,
});
