import react, {Fragment, createRef} from 'react';
import { NavLink } from "react-router-dom";
import { MapContainer, TileLayer, Popup, Polygon, Tooltip, Marker } from 'react-leaflet'
import { isPointInPolygon } from 'geolib';
import axios from 'axios';
import moment from 'moment';
import QrScanner from 'qr-scanner';
// import { KalmanFilter } from 'kalman-filter';
import L from "leaflet";
import "leaflet-rotatedmarker";
import GeometryUtil from 'leaflet-geometryutil';
import {Howl} from 'howler';
import { Oval, Audio } from 'react-loading-icons'
import RotatedMarker from '../Components/RotatedMarker';
import {
	baseUrl, 
	zoom, 
	noCoordsLatLng,
	noCoordsZoom,
	checkAreasInterval, 
	// activeFill, 
	basicFill, 
	leafletUrl,
	leafletUrlRetina,
	leafletAttribution,
	icon,
	iconDevice,
	iconLocation,
	deviceSecondsToRemove,
	fadeOutSingleInterval,
	fadeOutAllInterval,
	audioVolumeInitial,
	labels,
	useDevices,
	useClosest,
	categories,
} from '../Helpers/config';



let qrScanner = null;
let channelDevice = null;
// let audioSilence = null;
let wakeLock = null;


class Publics extends react.Component{

	constructor(props){
		super(props);

		this.state = {
			areasInterval:			null,
			coords: 				null,
			coordsData:				[],
			orientation:			null,
			addingDevice:			false,
			qrScanner:				null,
			deviceId:				null,
			deviceCoords: 			null,
			deviceLastUpdate: 		null,
			deviceCheckInterval:	null,
			deviceInitialSignal:	false,
			watchId:				null,
			closest:				null,
			areas:					[],
			audios:					{},
			audioLoaders:			{},
			activeAudioCount:		0,
			allAreas: 				[],
			markers:				[],
			userhandle: 			'',
			didZoomIn:				false,
			category:				categories[0].key,
		};

		this.mapRef = createRef();
	}



	componentDidMount(){

		const urlFilter = this.getUrlFilter();

		this.fetchMapData();

		// periodic get new areas
		if( this.state.areasInterval === null ){
			this.state.areasInterval = setInterval(() => {
				axios.get(`${baseUrl}cms/api/get.php?${urlFilter}`)
					.then(res => {
						this.setState({allAreas: res.data});
					})
					.catch(err => {
						console.log(err);
					});
			}, checkAreasInterval);
		}

		// user location permissions
		if(navigator.geolocation) {

			navigator.geolocation.getCurrentPosition((position) => {
				this.setGeoWatcher();
			});

			if( navigator.permission !== undefined ){
				navigator.permissions.query({name:'geolocation'})
					.then((permissionStatus) => {
						// on load
						if( permissionStatus.state === 'denied' ){
							console.log("Geolocation is not supported by this browser.");
						}
						if( permissionStatus.state === 'granted' ){
							this.setGeoWatcher();
							// this.zoomToCurrent();
						}
						
						// on change
						permissionStatus.onchange = (e) => {
							if( e.currentTarget.state === 'denied' ){
								this.state.coords = null;
								this.state.watchId = null;
								this.checkCollisions();
							}
							if( e.currentTarget.state === 'granted' ) {
								this.setGeoWatcher();
								// this.zoomToCurrent();
							}
						};
					}
				);
			}
		}

		// device allready added
		let lsItemId = window.localStorage.getItem('liaw_device') || null;
		this.state.deviceId = lsItemId;
		if( lsItemId !== null ){
			this.subscribeToDevice();
		}

		// wake lock
		this.doWebLock();
	}
	
	componentDidUpdate = (prevProps, prevState) => {

		if( this.props.checkParams !== prevProps.checkParams ) {
			navigator.geolocation.clearWatch(this.state.watchId);
			clearInterval(this.state.areasInterval)
			this.setState({areasInterval: null});
			this.state.audios = {};
			this.state.areas = [];
			this.state.deviceId = null;
			// this.state.allAreas = [];

			const urlFilter = this.getUrlFilter();
		
			if( urlFilter !== '' ) {
				axios.get(`${baseUrl}cms/api/get.php?${urlFilter}`)
					.then(res => {
						this.state.allAreas = res.data;
					});
				
				axios.get(`${baseUrl}cms/api/getMarkers.php?${urlFilter}`)
					.then(res => {
						this.state.markers = res.data;
					});
			}
		}

		const urlLocation = this.getUrlLocation();

		if( this.state.deviceId !== prevState.deviceId && this.state.deviceId !== null ){
			this.subscribeToDevice();
		}

		if( this.state.deviceInitialSignal !== prevState.deviceInitialSignal && this.state.deviceInitialSignal === true ){
			// this.uiAudio('broadcastDevice');
		}

		// zoom on user
		if( this.state.coords !== prevState.coords 
			&& this.state.coords !== null 
			&& this.state.didZoomIn === false 
			&& urlLocation === null
		){
			this.zoomToCurrent();
			this.state.didZoomIn = true;
		}

		// zoom on device
		if( this.state.deviceCoords !== prevState.deviceCoords 
			&& this.state.deviceCoords !== null 
			&& this.state.didZoomIn === false 
			&& urlLocation === null
		){
			this.zoomToDevice();
			this.state.didZoomIn = true;
		}

		// reset didZoom if no location
		if( this.state.deviceCoords === null && this.state.coords === null ){
			this.state.didZoomIn = false;
		}
	}
	
	componentWillUnmount = () => {
		if (navigator.geolocation && this.state.watchId) {
			navigator.geolocation.clearWatch(this.state.watchId);
		}

		clearInterval(this.state.areasInterval)
		this.setState({areasInterval: null});

		this.stopAllAudio();

		this.unsubscribeFromDevice();

		if( wakeLock !== null ){
			wakeLock.release()
				.then(() => {
					wakeLock = null;
				});
		}
	}


	fetchMapData = () => {
		const urlFilter = this.getUrlFilter();

		// get new areas
		axios.get(`${baseUrl}cms/api/get.php?${urlFilter}`)
			.then(res => {
				this.setState({allAreas: res.data});
			});
		
		// get markers
		axios.get(`${baseUrl}cms/api/getMarkers.php?${urlFilter}`)
			.then(res => {
				this.state.markers = res.data;
			});
	}

	doWebLock = async () => {
		if ('wakeLock' in navigator) {
			try {
				wakeLock = await navigator.wakeLock.request('screen');
			  } catch (err) {
				console.log(err);
			  }
		}
	}

	getUrlFilter = () => {
		let userId = '';
		let filterUrl = '';
		let urlParams = window.location.pathname;

		if( this.props.checkParams === true ){

			if( urlParams.includes(',') === false ){
				userId = urlParams.replace(
					`${process.env.PUBLIC_URL}/@`, 
					''
				);
				filterUrl = ( userId !== '' )
					? `&userId=${userId}` 
					: '';

				// filterUrl += `&category=${this.state.category}`;

				this.setState({
					userhandle: userId
				});
				console.log('filter', filterUrl);
				return filterUrl;
			}

		}

		return filterUrl; //`&category=${this.state.category}`;
	}

	getUrlLocation = () => {
		let urlParams = window.location.pathname;
		let parsedLocation = urlParams.replace(
			`${process.env.PUBLIC_URL}/@`, 
			''
		).split(',');

		if( parsedLocation.length === 3 ){
			return {
				lat: parseFloat(parsedLocation[0]), 
				lng: parseFloat(parsedLocation[1]),
				zoom: parseFloat(parsedLocation[2])
			};
		}

		return null;
	}

	setGeoWatcher = () => {
		if( navigator.geolocation ){
			navigator.geolocation.getCurrentPosition(
				this.showPosition,
				(err) => { console.log(err)},
				{enableHighAccuracy: true}
			)
			this.setState({
				watchId: navigator.geolocation.watchPosition(
					this.showPosition,
					(err) => { console.log(err)},
					{enableHighAccuracy: true}
				)
			});
		}

		if (window.DeviceOrientationEvent !== null && window.DeviceOrientationEvent !== undefined ) {
			window.addEventListener('deviceorientation', e => {
				let alpha = (e.webkitCompassHeading)
					? e.webkitCompassHeading 
					: e.alpha;
				
				this.setState({
					orientation: alpha
				})
			}, false);
		}
	}

	showPosition = ( position ) => {
		/*
		const {
			coordsData
		} = this.state;

		let coordsDataState = [...coordsData];
		
		coordsData.unshift([
			position.coords.latitude,
			position.coords.longitude
		])

		this.setState({
			coordsData: coordsDataState.slice(0,9)
		}, () => {
			const kFilter = new KalmanFilter({
				observation: {
					name: 'sensor',
					sensorDimension: 2
				},
				dynamic: {
					name: 'constant-position'
				}
			});
			const res = kFilter.filterAll(this.state.coordsData);
			console.log("original:", this.state.coordsData);
			console.log("filtered: ", res);
			
		})
		*/

		this.setState({ 
			coords: position.coords,
		}, () => {
			this.checkCollisions();
		});
	}

	checkClosest = () => {
		const {
			allAreas,
			deviceCoords,
			coords,
		} = this.state;

		if( allAreas.length === 0 ){
			return false;
		}

		let map = this.mapRef.current;

		if( map !== null ){

			let layerCoords = Object.values(map._layers)
				.filter(l => l._latlngs !== undefined)
				.map(l => l._latlngs);

			let closest = null;
			if( coords !== null ){
				closest = GeometryUtil.closest(map, layerCoords, [coords.latitude, coords.longitude]);
			}
			else if( deviceCoords !== null ){
				closest = GeometryUtil.closest(map, layerCoords, [deviceCoords.latitude, deviceCoords.longitude]);
			}

			if( closest !== null ){
				this.setState({closest}, () =>{
					map.flyTo([closest.lat, closest.lng], zoom);
					/*
					axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${closest.lat}&lon=-${closest.lng}`, function(data){
						console.log(data.address.road);
					});
					*/
				});
			}
		}
	}

	checkCollisions = () => {
		const {
			allAreas,
			coords,
			deviceCoords,
			deviceId,
		} = this.state;

		if( process.env.NODE_ENV === 'development' ){
			// console.log(coords, deviceCoords);
		}

		let areas = [];

		allAreas.forEach( area => {
			let polygonPoints = area.polygons.map(position => {
				return {
					latitude: position[0], 
					longitude: position[1]
				}
			});

			let isInArea = (coords !== null ) 
				? isPointInPolygon(
					{ 
						latitude: coords.latitude, 
						longitude: coords.longitude
					}, 
					polygonPoints
				)
				: false;

			let isDeviceInArea = (deviceCoords !== null) 
				? isPointInPolygon(
					{ 
						latitude: deviceCoords.latitude, 
						longitude: deviceCoords.longitude
					}, 
					polygonPoints
				)
				: false;

			let isAreaPlayable = ( 
				(
					// public area on user location
					(
						area.deviceId === ''
						&& isInArea
					)
					||
					// public area on device location
					(
						area.deviceId === ''
						&& isDeviceInArea
					)
				)
				|| 
				// private area on device location
				( 	isDeviceInArea 
					&& area.deviceId !== null
					&& deviceId !== null 
					&& deviceId !== '' 
					&& area.deviceId !== ''
					&& deviceCoords !== null
					&& deviceId === area.deviceId.toString() 
				)
			)
					? true
					: false;

			// console.log( area, deviceCoords, deviceId );

			if( isAreaPlayable ){
				areas.push(area);
				this.playAudio(area);
			}else{
				this.stopAudio(area)
			}
			
			this.setState({
				areas: areas,
			});
		})
	}



	subscribeToDevice = () => {

		if( channelDevice !== null ){
			return false;
		}

		// console.log('subscribing to ', this.state.deviceId);

		this.setState({
			deviceInitialSignal: false,
		});

		// device location
		channelDevice = window.pusher.subscribe(this.state.deviceId);
		channelDevice.bind('geo', (data) => {
			this.setState({
				deviceCoords: {
					latitude: parseFloat(data.lat),
					longitude: parseFloat(data.lng),
				},
				deviceInitialSignal: true,
				deviceLastUpdate: moment(),
			}, () => {
				// check last device's activity if tab is active
				// if tab is inactive, setInterval does not work and will disable device
				if( this.state.deviceCheckInterval === null && document.visibilityState === "visible" ){
					this.state.deviceCheckInterval = setInterval(() => {
						let now = moment();
						let diff = moment.duration(now.diff(this.state.deviceLastUpdate));
						let diffSeconds = diff.asSeconds();
						// device is offline
						if( diffSeconds > deviceSecondsToRemove ){
							clearInterval( this.state.deviceCheckInterval );
							if( this.state.coords !== null ){
								this.zoomToCurrent();
							}else{
								this.zoomToNothing();
							}
							this.setState({
								deviceCoords: null,
								deviceCheckInterval: null,
								deviceInitialSignal: false,
							}, () => {
								setTimeout(() => {
									this.checkCollisions();
								}, 100)
							});
						}
					}, 10000);
				}
				this.checkCollisions();

				if( window.gtag !== undefined 
					&& window.gtag !== null
					&& process.env.NODE_ENV !== 'development'
				){
					window.gtag('event', 'locator_subscribe', {
						deviceId: this.state.deviceId
					});
				}
			});
		});
	}

	unsubscribeFromDevice = () => {
		channelDevice = window.pusher.unsubscribe(this.state.deviceId);
		channelDevice = null;

		if( window.gtag !== undefined 
			&& window.gtag !== null
			&& process.env.NODE_ENV !== 'development'
		){
			window.gtag('event', 'locator_unsubscribe', {
				deviceId: this.state.deviceId
			});
		}
	}



	addDevice = () => {

		this.setState({
			addingDevice: true
		}, () => {
			let videoElem = document.querySelector('.qr-video');
			if( videoElem !== null ){
				qrScanner = new QrScanner(
					videoElem,
					(result) =>{
						if( typeof result === 'object' && result.hasOwnProperty('data') ){
							let statusElem = document.querySelector('.qr-status');
							statusElem.innerHTML = '';
							axios.get(`${baseUrl}cms/api/addDevice.php?id=${result.data}`)
								.then(res => res.data)
								.then(data => {
									if( data.status === 'ok' ){
										this.setState({ 
											deviceId: data.id,
											addingDevice: false,
										}, () => {
											this.subscribeToDevice();
											window.localStorage.setItem('liaw_device', data.id);
											this.closeAddDevice();

											this.uiAudio('addDevice');
										});
									}else{
										statusElem.innerHTML = data.msg

										if( window.gtag !== undefined 
											&& window.gtag !== null
											&& process.env.NODE_ENV !== 'development'
										){
											window.gtag('event', 'locator_error', {
												entryType: 'qr',
												id: result.data,
												msg: data.msg
											});
										}
									}
								});
						}
					}, 
					{returnDetailedScanResult: true}
				);
				qrScanner.start();
			}
		})	
	}

	addManualDevice = () => {
		let statusElem = document.querySelector('.qr-status');
		let deviceId = document.querySelector('[name="manual-device-id"]').value;
		statusElem.innerHTML = '';
		axios.get(`${baseUrl}cms/api/addDevice.php?id=${deviceId}`)
			.then(res => res.data)
			.then(data => {
				if( data.status === 'ok' ){
					this.setState({ 
						deviceId: data.id,
						addingDevice: false,
					}, () => {
						this.subscribeToDevice();
						window.localStorage.setItem('liaw_device', data.id);
						this.closeAddDevice();

						this.uiAudio('addDevice');
					});
				}else{
					statusElem.innerHTML = data.msg;

					if( window.gtag !== undefined 
						&& window.gtag !== null
						&& process.env.NODE_ENV !== 'development'
					){
						window.gtag('event', 'locator_error', {
							entryType: 'manual',
							id: deviceId,
							msg: data.msg
						});
					}
				}
			});
	}

	removeDevice = () => {
		this.closeAddDevice();
		this.unsubscribeFromDevice();
		clearInterval( this.state.deviceCheckInterval );
		this.setState({ 
			deviceId: null,
			deviceCoords: null,
		}, () => {
			setTimeout(() => {
				this.checkCollisions();
			}, 100)
			window.localStorage.removeItem('liaw_device');	
		});
	}

	closeAddDevice = () => {
		if( qrScanner !== null ){
			qrScanner.destroy();
		}
		setTimeout(() => {
			this.setState({
				addingDevice: false
			});
		},100);
	}



	zoomToLocation = (map) => {
		const urlLocation = this.getUrlLocation();
		if( urlLocation !== null ){
			if( map !== null ){
				// map.flyTo([coords.latitude, coords.longitude], zoom);
				map.setView(
					[urlLocation.lat, urlLocation.lng], 
					urlLocation.zoom
				);
			}
		}
	}

	zoomToCurrent = () => {
		const { coords } = this.state;
		let map = this.mapRef.current;
		if( map !== null && coords !== null ){
			// map.flyTo([coords.latitude, coords.longitude], zoom);
			map.setView([coords.latitude, coords.longitude], zoom);
		}
	}

	zoomToMarker = (marker) => {
		console.log(marker);
		let map = this.mapRef.current;
		if( map !== null ){
			map.setView(
				[marker.latlng.lat, marker.latlng.lng], 
				zoom
			);
		}
	}

	zoomToDevice = () => {
		const { deviceCoords } = this.state;
		let map = this.mapRef.current;
		if( map !== null && deviceCoords !== null ){
			// map.flyTo([deviceCoords.latitude, deviceCoords.longitude], zoom);
			map.setView([deviceCoords.latitude, deviceCoords.longitude], zoom);
			this.closeAddDevice();
		}
	}

	zoomToNothing = () => {
		let map = this.mapRef.current;
		if( map !== null ){
			// map.flyTo(noCoordsLatLng, noCoordsZoom);
			map.setView(noCoordsLatLng, noCoordsZoom);
		}
	}



	playAudio = (area) => {

		let audioObj = 		null;

		// check if audio playable


		// construct audio obj
		if( area.hasOwnProperty('stream') && area.stream !== '' ){
			audioObj = {
				src:		[area.stream],
				loop: 		false,
				html5: 		true,
				format: 	['mp3', 'aac'],
				preload: 	true,
				autoplay: 	true,
				volume: 	audioVolumeInitial,
			}
		}
		else if( area.hasOwnProperty('mp3')  && area.mp3 !== '' ){
			audioObj = {
				src:		[`${baseUrl}cms/api/audio/${area.mp3}`],
				loop: 		(area.repeat === '1' ) ? true : false,
				// html5: 		true,
				format: 	['mp3'],
				preload: 	true,
				autoplay: 	true,
				volume: 	audioVolumeInitial,
			}
		}
		else if( area.hasOwnProperty('wav') && area.wav !== '' ){
			audioObj = {
				src:		[`${baseUrl}cms/api/audio/${area.wav}`],
				loop: 		(area.repeat === '1' ) ? true : false,
				// html5: 		true,
				format: 	['wav'],
				preload: 	true,
				autoplay: 	true,
				volume: 	audioVolumeInitial,
			}
		}

		if( audioObj !== null ){

			// console.log(audioObj)

			let audio = this.state.audios[parseInt(area._id)];

			if( audio === undefined ){
				if( audioObj.src.length > 0 && audioObj.format.length > 0 ){
					audio = new Howl(audioObj);

					audio.on('fade', (e) => {
						setTimeout(() => {
							audio.stop();
						}, fadeOutSingleInterval)
					});
					audio.on('load',() => {
						this.setState({
							audioLoaders: Object.values(this.state.audioLoaders).filter(src => src !== audio._src)
						})
					});
					audio.on('play',() => {
						this.setState({
							audioLoaders: Object.values(this.state.audioLoaders).filter(src => src !== audio._src),
							activeAudioCount: Object.values(this.state.audios).filter( audio => audio.playing() === true ).length
						}, () => {
							if( window.gtag !== undefined 
								&& window.gtag !== null
								&& process.env.NODE_ENV !== 'development'
							){
								window.gtag('event', 'play_audio', {
									audio: JSON.stringify(audioObj),
									area: JSON.stringify(area)
								});
							}
						})
					});
					audio.on('end',() => {
						this.setState({
							activeAudioCount:  Object.values(this.state.audios).filter( audio => audio.playing() === true ).length
						})
					});

					// update state
					let { 
						audioLoaders, 
						audios 
					} = this.state;

					audios[parseInt(area._id)] = audio;
					
					if( audio.state() !== 'loaded' ){
						audioLoaders[parseInt(area._id)] = audio._src;
					}
					
					this.setState({
						audioLoaders,
						audios
					});

				}
			}else{
				if( audio.playing() === false && audioObj.loop === true ){
					audio.volume(audioVolumeInitial);
					audio.play();
				}else{
					audio.volume(audioVolumeInitial);
				}
			}
		}
	}

	stopAudio = (area) => {
		let audio = this.state.audios[parseInt(area._id)];
		if( audio !== undefined /*&& audio.playing() === true*/ ){
			audio.fade(
				audio.volume(), 
				0, 
				fadeOutSingleInterval + 100
			);

			this.setState({
				activeAudioCount: Object.values(this.state.audios).filter( audio => audio.playing() === true ).length
			})
		}
	}

	stopAllAudio = () => {
		Object.values(this.state.audios).forEach(audio => {
			audio.fade(.75, 0, fadeOutAllInterval + 100);
		})
	}

	setVolume = (sndId, e) => {
		// let audioElem = document.getElementById(`snd_${sndId}`);
		// audioElem.volume = e.target.value / 100;
		if( this.state.audios[parseInt(sndId)] !== undefined ){
			this.state.audios[parseInt(sndId)].volume(e.target.value / 100);
		}
	}

	uiAudio = (soundId) => {
		new Howl({
			src: [`${baseUrl}sound/${soundId}.mp3`],
			autoplay: true,
			loop: false,
			// html5: false,
			format: 'mp3',
			volume: .75,
			preload: true,
		});
	}


	setCategory = (category) => {
		this.setState({
			category
		}, () => {
			this.fetchMapData();
		})
	}


	render(){

		const {
			coords,
			orientation,
			deviceId,
			addingDevice,
			deviceCoords,
			allAreas,
			areas,
			audioLoaders,
			activeAudioCount,
			markers,
			closest,
			userhandle,
		} = this.state;

		let pos = {
			latitude: noCoordsLatLng[0], 
			longitude: noCoordsLatLng[1]
		};
		if( coords !== null ){
			pos = coords;
		}else if( deviceCoords !== null ){
			pos = deviceCoords;
		}

		let posZoom = ( coords === null && deviceCoords === null )
			? noCoordsZoom
			: zoom;

		const meClass = ( areas.length > 0 && coords !== null ) 
			? 'where-am-i where-am-i--active' 
			: 'where-am-i';

		const deviceClass = ( areas.length > 0 && deviceCoords !== null )
			? 'where-is-device where-is-device--active' 
			: 'where-is-device';

		return(

			<Fragment>
				<div>

					{/*** user handle */}
					{ userhandle !== null && userhandle !== "" && 
						<div 	className="user">
							@{userhandle}
						</div>
					}


					{ allAreas.length < 0  && 
						<div className='loading-panel'>
							{labels.areas.loadingAreas}
							<Oval width={100} height={100} />
						</div>
					}

					{ Object.values(audioLoaders).length > 0 &&
						<div className='audio-loaders'>
							<p>
								{labels.areas.trackLoading}
								&nbsp;{Object.values(audioLoaders).length} 
								&nbsp;{labels.areas.trackTitle}{(Object.values(audioLoaders).length > 1) ? 's': ''}...
							</p>
						</div>
					}

					{ allAreas.length >= 0 && 
						<Fragment>

							{/*** map */}
							<MapContainer 	ref={this.mapRef} 
											center={[pos.latitude, pos.longitude]} 
											zoom={posZoom} 
											minZoom={noCoordsZoom}
											maxZoom={18}
											autoPan={true} 
											scrollWheelZoom={false}
											whenReady={map => this.zoomToLocation(map.target)}
							>
								<TileLayer 	url={(L.Browser.retina) ? leafletUrlRetina : leafletUrl} 
											attribution={leafletAttribution}
											subdomains="abc"
											ext="png"
								/>

								{allAreas.map( (area, idx) => {
									// const isActive = areas.find(x => x._id === area._id);
									return <Polygon 
										key={`polygon_${idx}`} 
										positions={area.polygons} 
										// pathOptions={(isActive) ? activeFill : basicFill}
										pathOptions={basicFill}
									>
										{/*
										<Tooltip direction="bottom" offset={[0, 0]} opacity={1} sticky>
											<strong>{area.title.toUpperCase()}</strong>
										</Tooltip>
										*/}
									</Polygon>
								})}

								{markers.map( (marker, idx) => {
									let markerIcon;
									switch(marker.status) {
										/*
										case 'FUTURE':
											markerIcon = iconLocationFuture;
											break;
										
										case 'ACTIVE':
											markerIcon = iconLocation;
											break;
										case 'INACTIVE':
											markerIcon = iconLocation;
											break;
										*/
										default:
											markerIcon = iconLocation;
											break;
									}
									return <Marker
										key={`marker_${idx}`} 
										position={marker.latlng}
										icon={markerIcon}
										rotationAngle={0}
										zIndexOffset={10}
										riseOnHover={true}
									>
										<Tooltip 
											direction="bottom" 
											offset={[0, 0]} 
											opacity={1} 
											permanent={false}
											interactive={false}
											className="tooltip--marker"
										>
											<h2 style={{margin: '5px 0'}}>{marker.title}</h2>
											<p style={{margin: '5px 0'}}>Click for more...</p>
										</Tooltip>

										<Popup
											maxWidth={150}
											minWidth={150}
											// maxHeight={600}
											className="tooltip-popup"
										>
											<p style={{margin: '5px 0 15px 0'}}>
												{marker.description}
											</p>

											{ marker?.user?.userhandle !== undefined &&
												<p style={{margin: '5px 0 15px 0'}}>
													Artist: <strong>@{marker.user.userhandle}</strong>
												</p>
											}

											{/*
											<p style={{margin: '5px 0'}}>
												<a 
													href={`https://www.google.com/maps/dir/#/${marker.title}/@${marker.latlng.lat},${marker.latlng.lng},19z/`}
													target="_blank"
													rel="noreferrer"
												>
														Google Maps Directions
												</a>
											</p>
											*/}

										</Popup>

									</Marker>
								})}

								{ coords !== null && 
									<RotatedMarker
										key={`marker_location`} 
										position={[coords.latitude, coords.longitude]}
										icon={icon}
										rotationAngle={90 - orientation}
										rotationOrigin="center"
										zIndexOffset={9001}
										riseOnHover
									>
										<Tooltip direction="bottom" offset={[0, 0]} opacity={1} sticky className="tooltip--location">
											<h2 style={{margin: '5px 0'}}>{labels.areas.locationMarkerTitle}</h2>
											<p style={{margin: '5px 0'}}>{labels.areas.locationMarkerText}</p>
										</Tooltip>
									</RotatedMarker>
								}

								{ deviceCoords !== null && 
									<RotatedMarker
										key={`marker_device`} 
										position={[deviceCoords.latitude, deviceCoords.longitude]}
										icon={iconDevice}
										rotationAngle={0}
										rotationOrigin="center"
										zIndexOffset={9002}
									>
										<Tooltip direction="bottom" offset={[0, 0]} opacity={1} sticky className="tooltip--locator">
											<h2 style={{margin: '5px 0'}}>{labels.areas.locatorMarkerTitle}</h2>
											<p style={{margin: '5px 0'}}>{labels.areas.locatorMarkerText}</p>
										</Tooltip>
									</RotatedMarker>
								}

							</MapContainer>

							{/** messages */}
							{ deviceCoords === null && coords === null && deviceId === null && 
								<div className="messages">
									<p>
										<button onClick={() => window.location.reload()} 
												dangerouslySetInnerHTML={{__html: labels.areas.enableLocation}} />
									</p>
								</div>
							}

							{/*** categories ***/}
							<div className="filters">

								<div className="filters__categories">
									<select
										onChange={(e) => this.setCategory(e.target.value)}
									>
										{ categories.map( category => 
											<option 
												key={`category_${category.key}`}
												value={category.key}
											>
												{category.title}
											</option>
										)}
									</select>
								</div>

								<div className="filters__markers">
									{ markers
										.sort( (a,b) => (a.title < b.title) ? -1 : 1)
										.map( (marker, idx) => {
										return <span
											key={`marker_${marker._id}`}
											onClick={e => this.zoomToMarker(marker)}
										>
											{marker.title}
										</span>
										}
									)}
								</div>
							</div>

							{/*** actions */}
							<div 	className="actions">

								<NavLink className="go-back"  
									to={`${process.env.PUBLIC_URL}/`}>
									<span>&lt; PRIJATON</span>
								</NavLink>

								<div className="actions__right">

									{ deviceId !== null && 
										<Fragment>
											<div className={deviceClass} 
												onClick={this.addDevice} >
												{ deviceCoords === null && 
													<span>{labels.locator.waitingFor} </span> 
												}
												{ areas.length > 0 && deviceCoords !== null && 
													<Fragment>
														{labels.locator.title}
														<Audio width={12} height={12} />
													</Fragment>
												}
												{ deviceCoords === null && 
													<div className="device-loading">
														<Oval onClick={this.addDevice} width={16} height={16} />
													</div>
												}
											</div>
											
										</Fragment>
									}

									{useDevices === true && deviceId === null &&
										<div className="device-add"  
											onClick={this.addDevice} >
											{labels.locator.add}
										</div>
									}

									{ useClosest === true && /*areas.length === 0 &&*/ ( coords !== null || deviceCoords !== null ) &&  
										<div className="closest"  
											onClick={() => {this.checkClosest()}} >
											{ closest === null && 
												<Fragment>
													{ allAreas.length > 0 && 
														<span>CLOSEST?</span>
													}
													{ allAreas.length === 0 && 
														<span>No landscapes atm</span>
													}
												</Fragment>
											}
											{ closest !== null && 
												<span>
													Closest is {Math.ceil(closest.distance)}m away.
												</span>
											}
										</div>
									}

									{ coords !== null && 
										<div className={meClass}
											onClick={this.zoomToCurrent}>
											{labels.areas.me}
											{ areas.length > 0 && coords !== null && activeAudioCount > 0  && 
												<Audio width={12} height={12} />
											}
										</div>
									}

								</div>
							</div>



							{/*** mixer */}

							{/*
							<div className="volumes"  
								style={{ 
									position: 'fixed', 
									display: 'none', 
									flexDirection: 'row', 
									zIndex: 9997, 
									bottom: '100px', 
									left: '0px', 
									padding: '10px', 
									width: '100%',
									height: '100px',
									background: 'rgba(255,255,255,.75)'}}
							>
								{areas.map(area => { 
									let audioVol = ( this.state.audios[parseInt(area.id)] !== undefined ) 
										? this.state.audios[parseInt(area.id)]._volume * 100
										: 50;
									return <div key={`vol_${area._id}`} 
												style={{
													width: '50px',
													position: 'relative',
												}}
											>
												<input 
													orient="vertical"
													list="tickmarks"
													type="range" 
													min="0" 
													max="100" 
													step="10"
													defaultValue={audioVol}
													key={`mix_${area._id}`} 
													id={`mix_${area._id}`} 
													style={{
														height: '15px',
														width: '100px',
														transform: 'rotate(-90deg)', 
														transformOrigin: '50px 50px',
													}}
													onChange={(e) => this.setVolume(area._id, e)}
												/>
												<div style={{
													position: 'absolute',
													bottom: -10,
													left: 85,
													fontSize: '10px', 
													fontWeight: 700, 
													transform: 'rotate(-90deg)', 
													transformOrigin: '-35px 30px',
													textTransform: 'uppercase',
													width: '100px'
												}}>
													{area.title}
												</div>
									</div>
								})}

								<datalist id="tickmarks">
									<option value="0" label="0%"></option>
									<option value="50" label="50%"></option>
									<option value="100" label="100%"></option>
								</datalist>

							</div>
							*/}

							{/** modal add device */}
							{ addingDevice === true && 
								<div className="qr-code" >
									<div className="qr-code__wrapper">

										{ deviceId === null && 
											<Fragment>
												<p className="">
													{labels.locator.doScan}
													<br /><br />
													<video style={{margin: '0 auto', maxHeight: '300px', maxWidth: '400px', width: '100%'}} className="qr-video" />
												</p>
												
												<p className="qr-msg">
													{labels.locator.doManual}<br /><br />
													<input id="qrInput" className="qr-input" type="text" name="manual-device-id" defaultValue="" />
													<button className="qr-submit" onClick={this.addManualDevice}>+</button>
												</p>

												<p className="qr-status" style={{fontStyle: 'italic'}}></p>
											</Fragment>
										}

										{ deviceId !== null && deviceCoords === null && 
											<p>{labels.locator.waitingForFull}</p>
										}

										{ deviceCoords !== null && 
											<button className="qr-code__btn qr-code__btn--zoom" 
													onClick={this.zoomToDevice} >
												{labels.locator.zoomTo}
											</button>
										}

										{ deviceId !== null && 
											<button className="qr-code__btn qr-code__btn--remove"
													onClick={this.removeDevice} >
													{labels.locator.remove}
											</button>
										}

										<button className="qr-code__btn qr-code__btn--close" 
												onClick={this.closeAddDevice}>
											{labels.locator.closeModal}
										</button>

									</div>
								</div>
							}

						</Fragment>
					}

				</div>
			</Fragment>
		);
	}
}

export default Publics;