import {Fragment, useEffect, useState} from "react";
import axios from 'axios';
import moment from 'moment';
import { NavLink } from "react-router-dom";
// import Head from '../Components/Head';
import {
	baseUrl, 
	labels,
	newsletterIframeUrl,
} from '../Helpers/config';



function Posts(){

	const [posts, setPosts] = useState([]);

	useEffect(() => {
		axios.get(`${baseUrl}cms/api/getPosts.php`)
			.then(res => {
				setPosts(res.data);
			});
	},[])

	return (
		<div className="page page--posts">
		
			<div className="posts">

				<NavLink to={`${process.env.PUBLIC_URL}/`} className="page__back">
					<strong>&lt;</strong> {labels.news}
				</NavLink>

				<div  className="posts__container">
					{ posts.length === 0 && 
						<div className="post">
							<h2>{labels.no_posts}</h2>
						</div>
					}

					{ posts.length > 0 && 
						<Fragment>
							{ posts
								.sort((a,b) => parseInt(b.ts) - parseInt(a.ts))
								.map(post => 
								<div 	key={`post_${post.slug}_${post.id}`}
										className="post">

									{ post.title !== '' && 
										<h2>
											{post.title}
										</h2>
									}

									{ post.ts !== '' && 
										<h3>

											{ post.ts !== '' && 
												<span style={{margin: '0 10px 0 0'}}>
													{moment( parseInt(post.ts) * 1000 ).format('DD/MM/YY')} 
												</span>
											}
										</h3>
									}
									
									{ post.subtitle !== '' && 
										<h4>
											{post.subtitle}
										</h4>
									}

									{ post.description !== '' && 
										<p className="post__description" dangerouslySetInnerHTML={{__html: post.description}} />
									}

								</div>
							)}
						</Fragment>
					}

				</div>

				<div className="newsletter">
					<iframe 
						title="newsletter"
						src={newsletterIframeUrl}
					/>
				</div>

			</div>

		</div>
	);

}

export default Posts;