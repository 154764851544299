// import {Fragment, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import Head from '../Components/Head';


function NotFound(){

	return (
		<div className="page page--error">
		
			<Head />

			<div className="error">

				<h2>
					¯\_(ツ)_/¯
				</h2>
				<h3>
					Oooouuups, this is awkward...
				</h3>

				<NavLink to={`${process.env.PUBLIC_URL}/`}
						style={{
							margin: '50px 0 0 0',
							// fontSize: '14px', 
							fontWeight: 400,
							textDecoration: 'underline',
							color: '#222',
							cursor: 'pointer',
					}}>
					Click here
				</NavLink> 
				&nbsp;to return to homepage.

			</div>

		</div>
	);

}

export default NotFound;