import * as React from "react";
import { NavLink } from "react-router-dom";
import { labels, newsletterIframeUrl } from '../Helpers/config';
// import Head from '../Components/Head';


function Support(){

	

	return (
		<div className="page page--support">

			<div className="support">

				<NavLink to={`${process.env.PUBLIC_URL}/`} className="page__back">
					<strong>&lt;</strong> {labels.support}
				</NavLink>

				<p className="support__head">
					Prijaton is an <strong>independent service</strong>, developed and maintained by a small team.
				</p>

				<p className="support__content">
					Support us here <a href="https://buy.stripe.com/6oEcMU9V9g42fss000" target="_blank" rel="noreferrer">STRIPE</a>
				</p>
				
				<p className="support__newsletter">
					<iframe 
						title="newsletter"
						src={newsletterIframeUrl}
					/>
				</p>

			</div>

		</div>
	);

}

export default Support;