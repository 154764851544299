import * as React from "react";
import { NavLink } from "react-router-dom";
// import {baseUrl} from '../Helpers/config';



function Head(){


	return (

		<div className="head">

			<img className="head__logo"
				 src={`img/logo.png`} alt="liaw" />

			<div className="head__copy">
				<NavLink to={`${process.env.PUBLIC_URL}`}>
					<h1>
						prijaton
					</h1>
				</NavLink>
				<h3>
					Music for Landscapes
				</h3>
			</div>

		</div>
	);

}

export default Head;