import {useEffect, useState} from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";
// import { A2HSProvider } from  'react-a2hs'
import Head from '../Components/Head';
import {Howl} from 'howler';
import { Audio } from 'react-loading-icons'
import { IoVolumeOffSharp } from "react-icons/io5";

import {
	baseUrl, 
	labels,
} from '../Helpers/config';


let audio = null;

function Hello(){

	const [featured, setFeatured] = useState([]);
	const [muted, setMuted] = useState(true);

	useEffect(() => {

		axios.get(`${baseUrl}cms/api/getFeatured.php`)
			.then(res => {
				setFeatured(res.data);
			});
			
		// component will unmount
		return () => {
			if( audio !== null ){
				audio.stop();
			}
		}

	// eslint-disable-next-line
	},[])


	const toggleMute = () => {

		if( muted === true ){
			if( audio === null ){
				let audioObj = {
					src:		[`${baseUrl}cms/api/featured/${featured.audio}`],
					loop: 		false,
					format: 	[featured.audioFormat],
					preload: 	true,
					// html5:		false,
					autoplay: 	true,
					volume: 	1,
					onend: () => {
						setMuted( true );
					}
				}
				audio = new Howl(audioObj);
				audio.on('fade', (e) => {
					setTimeout(() => {
						audio.stop();
					}, 200)
				});
			}else{
				audio.volume(1);
				audio.play();
			}
		}else{
			audio.fade(1, 0, 500);
		}

		setMuted( !muted )
	}

	const gotoFeatured = (e) => {
		e.preventDefault();
		window.scroll({
			top: document.querySelector('.hello__video').offsetTop,
			behavior: "smooth"
		  });
	}

	return (
		<div className="page page--hello">

			<div className="hello">
			
				<div className="hello__left">

					<Head />

					<div className="hello__links">

						<NavLink 	to={`${process.env.PUBLIC_URL}/all`}
									className="hello__link hello__link--accent">
							{labels.explore}
						</NavLink>

						<NavLink 	to={`${process.env.PUBLIC_URL}/posts`}
									className="hello__link">
								{labels.news}
						</NavLink>

						<NavLink 	to={`${process.env.PUBLIC_URL}/about`}
									className="hello__link">
							{labels.about}
						</NavLink>

						<NavLink 	to={`${process.env.PUBLIC_URL}/support`}
									className="hello__link">
								{labels.support}
						</NavLink>

					</div>
					
				</div>

				{ typeof featured === 'object' && featured.hasOwnProperty('video') &&  
					<div 
						onClick={gotoFeatured}
						className="hello__scroll"  
					>
						<div className="hello__scroll-wrapper">
							{featured.type}
						</div>
					</div>
				}

				{ typeof featured === 'object' && ( featured.hasOwnProperty('video') || featured.hasOwnProperty('image') ) && 
					<div className="hello__video">

						<div className="hello__video-info">

							<label>
								{featured.type}
							</label>

							<div className="hello__video-info-wrapper">

								{featured.icon !== '' && 
									<img  	className={`hello__video-info-marker`} 
											src={`img/${featured.icon}.png`}  alt=""/>
								}

								<NavLink key={`featured`}
										className={`hello__video-info-title`}
										to={`${process.env.PUBLIC_URL}/@${featured.lat},${featured.lng},${featured.zoom}`}>
									{featured.name}
								</NavLink>

								<span className={`hello__video-info-toggler ${(muted === true)?'muted':''}`} onClick={toggleMute}>
									{ muted === false && <Audio width={20} height={12} /> }
									{ muted === true && <IoVolumeOffSharp /> }
								</span>
								
							</div>

						</div>
						
						{ featured.video !== '' && 
							<video autoPlay muted={muted} loop id="featuredVideo">
								<source src={`${baseUrl}cms/api/featured/${(window.innerWidth >= 1021) ? featured.video : featured.video_mobile}`} type={featured.videoType} />
							</video>
						}

						{ featured.video === '' && featured.image !== '' && 
							<img 
								alt="featured" 
								src={`${baseUrl}cms/api/featured/${(window.innerWidth >= 1021) ? featured.image : featured.image_mobile}`}
							/>
						}
					</div>
				}

			</div>

		</div>
	);

}

export default Hello;